<template>
    <div
        class="home"
        v-loading="loading"
    >
        <div class="white-bg">
            <div class="baccount-tit">
                <h3 class="g-title">验证当前登录</h3>
            </div>
            <div class="zpanle modifyp-panle">
                <div class="account-hd">
                    <div class="account-cnt">
                        <img
                            class="top-icon-img"
                            :src="$img.safe"
                        />
                        <p class="accont-slogin">验证登录密码</p>
                        <p class="min-tip">您正在进行{{nextRouterInfo.title}}的流程，在{{nextRouterInfo.title}}前，请先验证您的登陆密码。</p>
                    </div>
                </div>
                <div class="panner-bd modifypd">
                    <el-form
                        ref="accountForm"
                        :rules="rules"
                        :model="accountForm"
                        class="g-form"
                        style="width:370px;"
                        @submit.native.prevent
                    >
                        <el-form-item prop="password">
                            <el-input
                                type="password"
                                v-model="accountForm.password"
                                placeholder="请输入您的登录密码"
                            ></el-input>
                        </el-form-item>

                        <el-form-item class="btn-wrap ml-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="toValidPassword"
                                >确定</el-button>
                            </div>
                        </el-form-item>

                        <el-form-item class="btn-wrap back-wrap mr-b">
                            <div class="btn-cont">
                                <el-button
                                    type="primary"
                                    @click="$router.back(-1)"
                                >返回</el-button>
                            </div>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    name: "validEmail",
    metaInfo() {
        return {
            title: "验证登录密码 - 询龙网",
            meta: [
                {
                    name: "keywords",
                    content: ""
                }, {
                    name: "description",
                    content: ''
                }
            ]
        }
    },
    data() {
        return {
            loading: false,
            accountForm: {
                password: "" //密码
            },
            nextRouterInfo: {}, // 如果验证通过，跳转下一个页面的路由信息，如title和下一个路由的名称
            rules: {
                password: [
                    {
                        required: true,
                        message: "请输入登录密码",
                        trigger: ["change", "blur"]
                    }
                ]
            }
        };
    },
    computed: {
        ...mapState(["userInfo"])
    },
    methods: {
        //到服务器验证登录密码， 需要新增接口==================================
        toValidPassword() {
            let _this = this;
            _this.$refs["accountForm"].validateField("password", function (val, error) {
                if (!val) {
                    _this.loading = true; // 一下代码需要后台添加接口======================
                    _this.post(
                        "/user/user/validate_password", { password: _this.accountForm.password }, data => {
                            _this.loading = false;
                            if (data.code == 200) {
                                _this.$router.push({
                                    // 此步骤需要在添加验证密码接口后，验证成功后使用，此处为测试用
                                    name: _this.nextRouterInfo.routerName, // 跳转下一个路由的路由
                                    query: {
                                        type: _this.nextRouterInfo.type || '', // 如果用着相同的页面，可以用此参数来控制是否显示某些字段，如设置支付密码的type为1，不需要验证手机，修改支付密码为2，则需要验证手机
                                        title: _this.nextRouterInfo.title // 跳转下一个路由的标题，如修改密码，设置密码，修改手机，绑定手机等
                                    }
                                });
                            } else {
                                _this.$message.error(data.msg);
                                return;
                            }
                        }
                    );
                }
            });
        }
    },
    created() {
        this.nextRouterInfo = this.$route.query;
    }
};
</script>
<style lang="less" scoped>
@import "../../../styles/account.less";
</style>


